.historian-modal-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-height: 80%;
    box-shadow: 24;
    padding: 4;
    overflow: auto;
    background-color: white;
}

.historian-modal-header {
    display: flex;
    justify-content: flex-end;
}

.historian-modal-x {
    position: absolute;
    top: 0;
    right: 0;
}

.historian-modal-body {
    height: 75%;
    width: 100%;
}