.headerContainer {
  display: flex;
  align-items: center;
  padding: 16px;
  max-width: 1000px;
  width: 100%;
  justify-content: space-between;
  margin: auto;
  box-sizing: border-box;
}

.logo {
  width: 120px;
}

.addressBox {
  text-align: right;
  color: white;
} 