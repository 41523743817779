.tier-item {
    width: 100%;
    height: 70px;
    border-style:double;
    border-radius: 2px;
    border-width: 0px;
    margin-bottom: 5px;
    display: flex;
    box-shadow: 0 2px 5px 1px rgba(0,0,0,0.2);
}

.priceList {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}
.price-overlay {
    position: absolute;
    text-align: right;
    right: 0;
    vertical-align: center;
    font-size: .8em;
    color: rgb(68, 67, 67);
    padding-right: 20px;
    pointer-events: none;
}
.color {
    width: 2%;
    height: 100%;
}
.default {
    background-color: #7f8fa6;
}
.red {
    background-color: #e84118;
}
.green {
    background-color: #4cd137;
}
.combinedLeft {
    width: 30%;
}
.combinedRight {
    position: relative;
    width: 68%;
    border-left: grey;
    border: 2px;
}
.input {
    background-color:rgba(241, 241, 241, 0.712);
    border: 5px;
}
.input-disable {
    pointer-events: none;
    border: 1px solid rgb(167, 167, 167);
    background-color: lightgrey;
    color: rgb(63, 63, 63);
}

.upper {
    height: 60%;
    width: 100%;
    text-align: left;
    vertical-align: middle;
    padding: 5px;
}
.lower {
    width: 100%;
    height: 40%;
    text-align: left;
    vertical-align: middle;
}
.card-body {
    overflow-y: scroll;
    height: 80%;
}
.dropdown:hover .dropbtn {
    background-color: rgba(206, 205, 205, 0.514);
}
.dropdown-content a:hover {
    background-color: #f1f1f1;
}
.dropdown:hover .dropdown-content {
    display: block;
}
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
.dropdown {
    position: relative;
    display: inline-block;
  }
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
.drop {
    position: relative;
    display: inline-block;
}
.dropbtn {
    height: 100%;
    width: 100%;
    color: black;
    background-color: #fff;
    border: none;
    cursor: pointer;
  }