
.uploaderMainContainer {
    min-block-size: 500px;
    width: 100%;
    height: 100%;
    padding: 15px;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;

}

.uploaderDashedContainer {
    min-block-size: 500px;
    width: 100%;
    height: 100%;
    padding: 5px;
    display: flex;
    padding-top: 5px;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 4px;
    border-style: dashed;
    
}

.simpleFileUploaderContainer {
    min-width: 340px;
    max-height: 125px;
    min-height: 100px;
    width: 100%;
    height: 100%;
    padding: 8px;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
}

.simpleFileUploaderDashedContainer {
    max-height: 125px;
    width: 100%;
    height: 100%;
    padding: 5px;
    display: flex;
    padding-top: 5px;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 4px;
    border-style: dashed;
}
.simpleUploadFilePicture {
    min-height: 50px;
    max-height: 100px;
    min-width: 80px;
    max-width:fit-content;
}