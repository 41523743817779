.add-cards-btn {
  background-color: "#828DF8";
}

.add-cards-button {
  width: 25%;
  color: "#828DF8";
}

.add-cards-modal {
  margin-top: 120px;
  max-width: 50%;
}

.input-group {
  width: 100%;
  gap: 10px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 10px;
}

.search-by-id-input{
  border-radius: 10px;
  max-width: 150px;
}

.cards-to-add-input {
  border-radius: 10px;
  max-width: 150px;
}


/* BulkChangeStatus Styling */


.select-display-cell {
  font-size: .75 rem;
}

.autocomplete-player-cell {
  font-size: .75 rem;
}

.ac-display-cell {
  font-size: .75 rem;
}

.bulk-change-status-datagrid {
  height: 400;
  width: "100%";
}

.bulk-change-status-select-div {
  padding-left: 15px;
}

.bulk-change-status-select {
  font-size: .75 rem;
  width: 150px
}