.filter-autocomplete {
  width: 100%;
  height: 100%;

}

.filter-autocomplete .MuiInputBase-root {
  height: 100%;
}

.filter .MuiInput-root {
  font-size: 0.8125rem;
  height: 24px;
  display: flex;
  align-items: center;
}

.filter .MuiInput-input {
  padding: 0;
  height: auto;
}