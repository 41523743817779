.repack-store-preview {
  width: 100%;
  margin: 0 2% 0 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85vh; 
}

.cart-view {
  flex: 1;
  height: 50%;
  overflow-y: auto;
  margin-bottom: 10px;
}


.repacks {
  width: 100%;
  display: inline-block;
  margin: 0 2% 0 2%;
  height: 80vh;
}

.repack-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-y: auto;
}

.page-header {
  width: 100%;
  height: 15vh;
}
