.fill {
    height: 100%;
    width: 100%;
}
.fixTable {
    height: 100%;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    overflow-y: hidden;
}
.darken {
    background-color: rgba(0, 0, 0, 0.096);
}
.tableBody {
    height: 100%;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    overflow-y: scroll;
    overflow: overlay;
}
.selected {
    background-color: #52a0c779;
}