.active-link {
  color: var(--mui-palette-secondary-main);
}

.active-link .menu-icon {
  color: var(--mui-palette-secondary-main);
}

.dashboard-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.dashboard-page > dashboard-nav {
  display: flex;
  flex: 0 1 auto;
  position: fixed;
  top: 0;
}
.hover {
  cursor: pointer;
}
.dashboard-nav {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #2f3640;
}
.dashboard-nav-left {
  align-items: left;
}
.dashboard-nav-right {
  right: 0;
  display: flex;
  align-items: center;
  color: white;
}
.dashboard-display {
  width: 97%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dashboard-body {
  width: 100%;
  height: 100%;
  background-color: #f5f6fa;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  padding: 5px;
  overflow-y: scroll;
}
.dashboard-body2 {
  width: 100%;
  height: 100%;
  display: flex;
}

.card {
  background-color: rgba(0, 0, 0, 0);
  margin: 5px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.card.wholePage {
  height: 98%;
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
}
.card.halfPage {
  height: 98%;
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
}
.card.product {
  height: 98%;
  width: 26%;
}
.sm-text {
  font-size: 12px;
}
@media only screen and (max-width: 1380px) {
  .card {
    margin: 2px;
  }
  .card.halfPage {
    height: 54%;
    width: 100%;
  }
  .card.product {
    height: 44%;
    width: 100%;
  }
}
.dashboard-page > dashboard-menu {
  display: flex;
  flex-direction: column;
}
.dashboard-menu {
  background-color: #292f36;
  height: 100%;
  width: 50px;
  align-items: center;
}

.open .dashboard-menu {
  width: 200px;
}
.menu-header {
  height: 40px;
  width: 100%;
  display: flex;
  margin-left: auto;
  margin-right: 0;
}
.menu-item {
  cursor: pointer;
  width: 100%;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.055);
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  justify-content: center;
}
.menu-item:hover {
  background-color: rgba(0, 0, 0, 0.233);
}
.menu-text {
  display: none;
}
.open .menu-text {
  color: #f5f6fa;
  font-weight: bold;
  display: inline-block;
}
.open .menu-item {
  justify-content: left;
}
.menu-icon {
  height: 45%;
  color: #f5f6fa;
}
.open .menu-icon {
  margin-left: 10px;
}
.version-box {
  display: flex;
  align-items: center;
  color: #f5f6fa;
  margin-top: 30%;
  transition: all 0.3s ease;
}

.version-text {
  display: flex;
  flex-direction: column;
  color: #f5f6fa;
  transition: opacity 0.3s ease;
}
.discord-box {
  display: flex;
  align-items: center;
  color: #f5f6fa;
  margin-top: 10%;
  transition: all 0.3s ease;
}

.discord-icon {
  margin-right: 10px;
  font-size: 24px;
  transition: font-size 0.3s ease;
}

.discord-text {
  display: flex;
  flex-direction: column;
  color: #f5f6fa;
  transition: opacity 0.3s ease;
}

.closed .discord-icon {
  font-size: 24px;
  margin-left: 10px;
  margin-top: 0;
}

.hamburger {
  justify-content: right;
}
.hamburger {
  margin: auto;
  display: inline-block;
  right: 0;
  height: 38px;
  cursor: pointer;
}
.hamburger1,
.hamburger2,
.hamburger3 {
  width: 35px;
  height: 5px;
  background-color: #f5f6fa;
  margin: 6px 0;
  transition: 0.4s;
}
.open .hamburger1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}
.open .hamburger2 {
  opacity: 0;
}
.open .hamburger3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.whole-menu-item {
  position: relative;
  width: 100%;
  height: 40px;
  display: inline-block;
  border-bottom: 1px dotted black;
}
