
.report-input {
    height: 100%;
    width: 100%;
    justify-content: center;
}
.smallText {
    font-size: .85em
}
.small-text {
    font-size: .7em;
}
.smaller-text {
    font-size: .6em;
}
.tablePadding {
    padding: .1rem;
}
.center {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}
.toolTip {
    position: relative;
    visibility: visible;
  }

.toolTip .tooltiptext {
    visibility: Hidden;
    background-color:#FFFFE0;
    color: rgb(0, 0, 0);
    text-align: center;
    padding: 4px 0;
    border-radius: 3px;
    font-weight: normal;
    font-size: 1em;
    width: 120px;
    position: absolute;
    z-index: 1;
}
.toolTip:hover .tooltiptext {
    visibility: visible;
}

.strikethrough {
    text-decoration: line-through;
}
.priceColFlex {
    display: flex;
    flex-direction: column;
    width: 10.5%;
    margin-right: 4px;
}
.columnHolder {
    display: flex;
    flex-direction: row;
}