.dataGrid {
  height: calc(100vh - 140px);
}

.dataGrid :global(.MuiDataGrid-cell) {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.dataGrid :global(.MuiDataGrid-cell:hover) {
  color: var(--primary-main);
}

.dataGrid :global(.MuiDataGrid-row:nth-of-type(even)) {
  background-color: rgba(0, 0, 0, 0.04);
} 