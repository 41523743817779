.card {
    width: 100%;
    height: 98%;
    margin: .5em;
}
.topbar {
    background-color: #fff;
    height: 30px;
    border-bottom: 1px solid #e0e1e7;
    display: flex;
    flex: 1 0 auto;
}
.dashboard-page-flip {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.dashboard-nav-flip {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}
.dashboard-nav-left-flip {
    width: 100px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-right: 1px solid #e0e1e7;
    padding-top: 1em;
    flex-shrink: 0;
}
.active {
    background-color: rgba(232, 228, 228, 0.407) !important;
    color: #0097e6 !important;
}
.dashboard-nav-left-item {
    width: 100%;
    height: 40px;
    padding-left: 1em;
    color: #707887;
    font-weight: 500;
    font-size: 14px;
}
.dashboard-nav-left-item:hover {
    cursor: pointer;
    color: black;
}
.dashboard-nav-right-flip {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    background-color: #f5f5f5;
    padding: 10px;
}
.foot-spacing {
    margin-right: 5px;
}
.breakCard {
    width: 40% !important;
    height: 92% !important;
}
.searchCard {
    width: 60% !important;
    padding: 10px;
    height: 92% !important;
}
.clickable {
    cursor: pointer;
}
.main-card {
    position: relative;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    width: 100%;
    height: 90%;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}
.bold {
    font-weight: 500;
}
.disassembly {
    width: 50%;
}
.back-button {
    width: 100px;
    background-color: #fff;
    border: 0px;
    font-size: .9em;
    color: #575b64;
    border-right: 1px solid rgba(0,0,0,.125);
}
.back-button:hover {
    color: black;
    background-color:rgba(232, 228, 228, 0.407);
}
.row-display {
    display: flex;
    margin-bottom: 5px;
    flex-direction: row;
}
.selectFix {
    width: 250px;
    padding-left: 10px;
}
.live-border {
    background-color: rgba(0, 255, 0, 0.505);
}
.live-border:hover {
    background-color: rgba(0, 255, 0, 0.605);
}
td.hobbyHighlight {
    background-color: lightblue !important;
}