.homepage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #212529;
}

.homepage-nav {
    height: 60px;
    width: 100%;
    display: flex;
    background-color: #212529;
}

.homepage-body {
    width: 1000px;
    height: 100%;
    background-color: #f5f6fa;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    margin: auto;
    overflow-y: hidden;
    flex-wrap: wrap;
    padding-bottom: 20px; 
}

.name-cell {
    text-wrap: wrap;
    line-height: 1.2em;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: center;
    vertical-align: middle;
}

@media only screen and (max-width: 1000px) {
    .homepage-body {
        padding: 2px;
        border-radius: 0px;
        width: 100%;
    }
}