.singles-bar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.singles-width {
    width: 90%;
}
.singles-button {
    width: 8%;
}

.pagination-box { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 5px;
}

.input-group {
    display: flex;
    justify-content: flex-start;
    align-items: left;
    flex-direction: row;
    gap: 5px;
}

.input-group-div {
    width: 50px !important;
}

.input-group-text{
    border-radius: 12px !important;
    height: 30px;
    font-size: 0.75rem;
    text-align: start !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  .input-group-text::-webkit-inner-spin-button,
  .input-group-text::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

.form-control-box { 
    display: flex;
    justify-content: flex-start;
    align-items: left;
    width: 100px; 
}

.form-control { 
    width: 100%;
}

.btn {
    font-size: .8125rem !important;
    width: 125px ;
    display: inline-block;
    padding: 5px !important;
}

.row-count{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.editable-column {
    font-size: 0.75rem !important; 
  }


/* AUTOCOMPLETE SKU ITEM */
.autocomplete-container-flag {
  width: 250px;
  text-align: center; 
  border: 1px solid red !important;
  outline: none !important;
  margin: 0px !important;
}
.autocomplete-container-flag input {
  border-bottom-color: rgb(18, 24, 40);
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(18, 24, 40);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgb(18, 24, 40);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgb(18, 24, 40);
  border-top-style: none;
  border-top-width: 0px;
  box-sizing: border-box;
  color: rgb(18, 24, 40);
  display: flex;
  flex-direction: column;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  line-height: 24px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  min-width: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  position: relative;
  text-align: start;
  text-size-adjust: 100%;
  vertical-align: top;
  width: 190px;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.autocomplete-container-flag .MuiAutocomplete-listbox li {
  font-size: 0.75rem !important;
}



.autocomplete-container-filter {
  width: 250px;
  text-align: center; 
  outline: none !important;
  margin: 0px !important;
}
.autocomplete-container-filter input {
  align-items: center;
  box-sizing: border-box;
  color: rgb(18, 24, 40);
  cursor: text;
  display: flex;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  font-weight: 400;
  height: 32px;
  line-height: 23px;
  margin-top: 16px;
  position: relative;
  text-align: start;
  text-size-adjust: 100%;
  width: 190px;
  -webkit-box-align: center;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.autocomplete-container-flag .MuiAutocomplete-listbox li {
  font-size: 0.75rem !important;
}



.autocomplete-container {
    width: 250px;
    text-align: center; 
    border: none !important;
    outline: none !important;
  }
  
  .autocomplete-container input {
    font-size: 0.75rem !important;
  }
  
  .autocomplete-container .MuiAutocomplete-listbox li {
    font-size: 0.75rem !important;
  }


  
  /* SELCT CELL */
  .select-container {
    width: 100px;
    text-align: center; 
    border: none !important;
    outline: none !important;
  }
  
  .select-container .MuiSelect-root {
    font-size: 0.75rem !important;
    border: none !important;
    outline: none !important;
  }
  
  .select-container .MuiMenuItem-root {
    font-size: 0.75rem !important;
    border: none !important;
    outline: none !important;
  }

  .editing-row {
    background-color: blue; 
  }


  .Mui-even{
    background-color: white;
  }

  .Mui-odd{
    background-color: #f5f5f5;
  }

  