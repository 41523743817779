.filterButton {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterMenu {
  max-height: 300px;
  width: 200px;
} 