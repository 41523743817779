.clear-cards-modal {
    top: 25%;
    transform: translateY(-50%);
    max-height: 90vh;
    overflow-y: auto;
  }

  .bulk-creator-header {
    padding-top: 1%;
    padding-left: 1%;
  }

.add-cards-modal .modal-dialog {
    max-width: 80%;
  }
  
  .add-cards-modal-body {
    max-height: 70vh; 
    overflow-y: auto; 
    display: flex;
    flex-direction: column;
  }
  
  .add-cards-modal-body .MuiDataGrid-root {
    flex-grow: 1; 
    overflow: auto; 
  }

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .fourteen {
    font-size: 14px;
  }
  