/* filterstyles.css */

.filter-name {
    text-align: center;
    white-space: nowrap;
    width: 400px;
    font-size: 0.8125rem;
    line-height: 1.75;
    height: 10px;
  }
  